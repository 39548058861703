import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const BookingSSystem = () => {
    const [loading, setLoading] = useState(true);
    const [slotCount, setSlotCount] = useState(null);
    const [date, setDate] = useState("");

    // Automatically attempt to book a slot when the component mounts
    useEffect(() => {
        handleBookSlot();
    }, []);

    const handleBookSlot = async () => {
        try {
            const response = await fetch("https://support.thinklay.com/Rishabh - Copy/increment_booked_slot.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to book slot. Please try again later.");
            }

            const data = await response.json();
            setDate(data.date);
            setSlotCount(data.slots);
            alert(`Slot booked successfully for ${data.date}. Total slots: ${data.slots}`);

            // Redirect to home page after successful booking
            window.location.href = "https://cyberrishabh.com";
        } catch (error) {
            console.error("Error booking slot:", error);
            alert("Error booking slot: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1 className="mb-4">Booking in Progress...</h1>
                <p className="lead">Please wait while we book your slot.</p>

                {loading ? (
                    <p>Booking...</p>
                ) : slotCount !== null ? (
                    <p>Booked slots for {date}: {slotCount}</p>
                ) : (
                    <p>Booking failed. Please try again.</p>
                )}
            </div>
        </div>
    );
};

export default BookingSSystem;
