import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import RazorpayButton from "./razor"; // Razorpay Button Component

const BookingSystem = () => {
  const availableDates = Array.from({ length: 1 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i);
    return date.toDateString();
  });

  const [bookedSlots, setBookedSlots] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [showPaymentButton, setShowPaymentButton] = useState(false);

  const date = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
  const currentDate = new Date(date).toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'

  // Fetch booked slots from server when component mounts
  useEffect(() => {
    fetchBookedSlots();
  }, []);

  const fetchBookedSlots = async () => {
    try {
      const response = await fetch("https://support.thinklay.com/Rishabh - Copy/get-booked-slots.php");
      const data = await response.json();
      setBookedSlots(data);
    } catch (error) {
      console.error("Failed to fetch booked slots", error);
    }
  };

  const handleBookSlot = (date) => {
    setSelectedDate(date);
    setShowPaymentButton(true); // Show Razorpay button in the modal
  };

  const handleClose = () => {
    setShowPaymentButton(false);
    setSelectedDate(null); // Reset selected date when modal is closed
  };

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="mb-4" style={{ color: "white" }}>
          Book a 1-on-1 Session
        </h1>
        <p className="lead" style={{ color: "white" }}>
          You can book up to 3 slots per day.
        </p>
      </div>

      <div className="row justify-content-center">
        {availableDates.map((date) => {
          const slotsForDate = bookedSlots[date] || 0;
          const isSlotFull = slotsForDate >= 3;

          return (
            <div key={date} className="col-lg-4 col-md-6 mb-3">
              <div className="card shadow-sm">
                <div className="card-body text-center">
                  <h5 className="card-title">{date}</h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleBookSlot(date)}
                    disabled={isSlotFull}
                  >
                    {isSlotFull ? "Slot Full" : "Book Slot"}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Bootstrap Modal for Razorpay Button */}
      <Modal show={showPaymentButton} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Complete Your Booking for {selectedDate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDate && bookedSlots[currentDate] < 3 ? (
            <RazorpayButton />
          ) : (
            <p className="text-center text-danger">Slot Full</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BookingSystem;
