import React, { useEffect } from "react";

const RazorpayButton = () => {
useEffect(() => {
const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.setAttribute("data-payment_button_id", "pl_PJTKMU5jvLJOaf");
script.async = true;
document.getElementById("razorpay-button-container").appendChild(script);
}, []);
    return (
<div>
    <form id="razorpay-button-container">
        {/* Razorpay payment button script will be appended here */}
    </form>
</div>
);
};

export default RazorpayButton;